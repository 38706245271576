import { type Asset, getChainflipId, type Chain } from '@chainflip/sdk/swap';
import { type BoostBalanceInfo } from '@/shared/hooks/useBoostPoolInfo';
import {
  type OnboardedContext as IOnboardedContext,
  createStateChainAccountContext,
} from '@/shared/hooks/useStateChainAccount';
import { TokenAmount, entries } from '@/shared/utils';
import { type ChainflipAsset, chainflipAssets, readAssetValue } from '@/shared/utils/chainflip';
import { POLKADOT_JS_APP_NAME } from '../helpers/constants';

type AccountInfo = {
  totalBalance: number | null;
  allBalances: Record<ChainflipAsset, TokenAmount> | null;
  boostBalances: BoostBalanceInfo[] | null;
};

export const { StateChainAccountProvider, useStateChainAccount } = createStateChainAccountContext({
  parseAccountInfo: (account, assetPrices): AccountInfo => {
    let allBalances: Record<ChainflipAsset, TokenAmount> | null = null;
    let boostBalances: BoostBalanceInfo[] | null = null;

    if (account?.role === 'liquidity_provider') {
      allBalances = Object.fromEntries(
        chainflipAssets.map((asset) => [
          asset,
          TokenAmount.fromAsset(readAssetValue(account.balances, asset), asset),
        ]),
      ) as Record<ChainflipAsset, TokenAmount>;

      boostBalances = Object.entries(account.boost_balances).flatMap(([chain, info]) =>
        Object.entries(info).flatMap(([symbol, balances]) => {
          const asset = getChainflipId({ chain: chain as Chain, asset: symbol as Asset });

          return balances.map((balance) => {
            const availableAmount = TokenAmount.fromAsset(
              balance.available_balance,
              asset as ChainflipAsset,
            );
            const unavailableAmount = TokenAmount.fromAsset(
              balance.in_use_balance,
              asset as ChainflipAsset,
            );

            return {
              pool: {
                asset: asset as ChainflipAsset,
                feeTierPips: balance.fee_tier,
              },
              isWithdrawing: balance.is_withdrawing,
              availableAmount: balance.available_balance.toString(),
              availableAmountValueUsd: availableAmount
                .mul(assetPrices[asset as ChainflipAsset] ?? 0)
                .toFixed(),
              unavailableAmount: balance.in_use_balance.toString(),
              unavailableAmountValueUsd: unavailableAmount
                .mul(assetPrices[asset as ChainflipAsset] ?? 0)
                .toFixed(),
            } as BoostBalanceInfo;
          });
        }),
      );
    }

    let totalBalance: number | null = null;

    if (allBalances) {
      totalBalance = 0;
      for (const [asset, amount] of entries(allBalances)) {
        const usd = amount.mul(assetPrices[asset] ?? 0).toFixed();
        totalBalance += Number(usd);
      }
    }

    return { totalBalance, allBalances, boostBalances };
  },
  polkadotAppName: POLKADOT_JS_APP_NAME,
  onboardedRole: 'liquidity_provider',
});

export type OnboardedContext = IOnboardedContext<AccountInfo, 'liquidity_provider'>;

export {
  type DepositChannel,
  type SendExtrinsic,
  type EstimateExtrinsicFee,
} from '@/shared/hooks/useStateChainAccount';
