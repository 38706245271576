import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  type CfUnregisteredAccount,
  type CfValidatorAccount,
  type CfBrokerAccount,
  type CfLiquidityProviderAccount,
} from '@chainflip/rpc/types';
import * as base58 from '@chainflip/utils/base58';
import { bytesToHex } from '@chainflip/utils/bytes';
import * as ss58 from '@chainflip/utils/ss58';
import { uncapitalize } from '@chainflip/utils/string';
import { type ApiPromise } from '@polkadot/api';
import type { SubmittableResultValue } from '@polkadot/api/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useChainflipAssetPrices } from '@/shared/hooks';
import { getAllBoostPoolBalancesForAccount, getBoostPoolCacheQuery } from '@/shared/queries/boost';
import { accountInfoRpcKey, getAccountPoolOrderQueryKey } from '@/shared/queryKeys';
import { unreachable, TokenAmount, assert } from '@/shared/utils';
import { type ChainflipAsset, type ChainflipChain, chainConstants } from '@/shared/utils/chainflip';
import { deferredPromise } from '@/shared/utils/promises';
import { MAX_TICK, MIN_TICK } from '@/shared/utils/tickMath';
import { type ChainflipAccountId, usePolkadot } from './usePolkadot';
import { type LiquidityDepositAddressReady, getEventArgs } from '../utils/polkadot';
import makeRpcRequest, { initRpcWsConnection, getChainAndAsset } from '../utils/rpc';

type Address = string;

type SendExtrinsicMap = {
  'open-channel': [asset: ChainflipAsset, boostFee?: number];
  'set-lp-role': [];
  'set-refund-address': [chainId: ChainflipChain, address: Address];
  'withdraw-liquidity': [chainId: ChainflipChain, asset: ChainflipAsset, amount: TokenAmount];
  'redeem-flip': [flipperinoAmount: 'Max' | { Exact: string }, ethAddress: string];
  'set-limit-order': [
    {
      baseAsset: ChainflipAsset;
      quoteAsset: ChainflipAsset;
      side: 'Buy' | 'Sell';
      orderId: bigint;
      tick: number;
      sellAmount: TokenAmount;
    },
  ];
  'set-range-order': [
    {
      baseAsset: ChainflipAsset;
      quoteAsset: ChainflipAsset;
      orderId: bigint;
      lowerTick: number;
      upperTick: number;
      maxBaseAmount: TokenAmount;
      maxQuoteAmount: TokenAmount;
    },
  ];
  'set-broker-role': [];
  'add-boost-funds': [asset: ChainflipAsset, amount: TokenAmount, poolTier: number];
  'stop-boosting': [asset: ChainflipAsset, poolTier: number];
};

export type DepositChannel = LiquidityDepositAddressReady & {
  blockNumber: number;
};

export type ExtrinsicResultMap = {
  'set-lp-role': { hash: string };
  'set-refund-address': { hash: string };
  'open-channel': DepositChannel;
  'withdraw-liquidity': {
    hash: string;
    blockNumber: number;
    extrinsicIndexInBlock?: number;
  };
  'redeem-flip': { hash: string };
  'set-limit-order': { hash: string };
  'set-range-order': { hash: string };
  'set-broker-role': { hash: string };
  'add-boost-funds': { hash: string };
  'stop-boosting': { hash: string };
};

type SendExtrinsicOptions = { onSubmitted: () => void };

type ExtrinsicArgsWithOptions<T extends keyof SendExtrinsicMap> = [
  { args: SendExtrinsicMap[T] } & SendExtrinsicOptions,
];

function isArgsWithOptions<T extends keyof SendExtrinsicMap>(
  args: SendExtrinsicMap[T] | ExtrinsicArgsWithOptions<T>,
): args is ExtrinsicArgsWithOptions<T> {
  return (
    Array.isArray(args) &&
    args.length === 1 &&
    typeof args[0] === 'object' &&
    args !== null &&
    'args' in args[0] &&
    'onSubmitted' in args[0]
  );
}

export type SendExtrinsic = <T extends keyof SendExtrinsicMap>(
  method: T,
  ...args: SendExtrinsicMap[T] | ExtrinsicArgsWithOptions<T>
) => Promise<ExtrinsicResultMap[T]>;

export type EstimateExtrinsicFee = <T extends keyof SendExtrinsicMap>(
  method: T,
) => Promise<TokenAmount>;

const createError = (dispatchErr: SubmittableResultValue['dispatchError']): Error | null => {
  if (!dispatchErr) return null;

  if (dispatchErr.isModule) {
    const { name, section, docs } = dispatchErr.registry.findMetaError(dispatchErr.asModule);

    const err = Error(`${section}.${name}:\n${docs.join(' ')}`, {
      cause: dispatchErr,
    });

    if (err.stack) err.stack = err.stack.split('\n').slice(0, 2).join('\n');

    return err;
  }

  return new Error(
    `The submitted extrinsic failed with an unexpected error: ${dispatchErr.toString()}`,
  );
};

type RequireKeys<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};

type Remap<T, K extends keyof T, V> = Omit<T, K> & { [P in K]: V };

type AccountInfo =
  | Remap<CfUnregisteredAccount, 'flip_balance', TokenAmount>
  | Remap<CfBrokerAccount, 'flip_balance', TokenAmount>
  | Remap<CfValidatorAccount, 'flip_balance', TokenAmount>
  | Remap<CfLiquidityProviderAccount, 'flip_balance', TokenAmount>;

export type Context<T, R extends AccountRole> = T & {
  connect: VoidFunction;
  account: Role[R] | null;
  accountInfo: AccountInfo | null;
  accountId: ChainflipAccountId | null;
  sendExtrinsic: SendExtrinsic;
  estimateExtrinsicFee: EstimateExtrinsicFee;
  isLoading: boolean;
  flipBalance: TokenAmount | null;
  AccountSuspense: (props: {
    fallback: JSX.Element;
    children: (context: OnboardedContext<T, R> & { fallback: JSX.Element }) => React.ReactNode;
  }) => JSX.Element | null;
  runtimeVersion: string | null;
};

export type Role = {
  liquidity_provider: Remap<CfLiquidityProviderAccount, 'flip_balance', TokenAmount>;
  broker: Remap<CfBrokerAccount, 'flip_balance', TokenAmount>;
};

export type AccountRole = keyof Role;

export type OnboardedContext<T, R extends AccountRole> = Omit<
  RequireKeys<Context<T, R>, 'account' | 'accountId' | keyof T>,
  'account'
> & { account: Role[R] };

type CreateContextOpts<T, R extends AccountRole> = {
  parseAccountInfo: (
    account: AccountInfo | null,
    prices: Record<ChainflipAsset, number | undefined>,
  ) => T;
  polkadotAppName: string;
  onboardedRole: R;
};

export function createStateChainAccountContext<T, R extends AccountRole>({
  parseAccountInfo,
  onboardedRole,
}: CreateContextOpts<T, R>) {
  const StateChainAccountContext = createContext<Context<T, R> | null>(null);

  function StateChainAccountProvider({ children }: { children: React.ReactNode }) {
    const { prices: assetPrices } = useChainflipAssetPrices();
    const [apiPromise, setApiPromise] = useState<ApiPromise | null>(null);
    const isLoading = !apiPromise;
    const {
      selectedAccount: polkadotAccount,
      selectedSigner: polkadotSigner,
      isInitializingExtensions,
    } = usePolkadot();

    const init = useCallback(async () => {
      const api = await initRpcWsConnection();
      if (api) {
        setApiPromise(api);
      }
    }, []);

    const runtimeVersion = useMemo(() => {
      if (apiPromise === null) return null;

      const version = apiPromise.runtimeVersion.specVersion.toNumber().toString();

      // will we go to v.1.10.0? this won't work
      return `v${version[0]}.${version[1]}.${version.slice(2)}`;
    }, [apiPromise]);

    const { data: account = null, refetch } = useQuery({
      queryKey: accountInfoRpcKey(polkadotAccount?.idSs58),
      queryFn: () => polkadotAccount && makeRpcRequest('cf_account_info', polkadotAccount.idSs58),
      refetchInterval: 5000,
      select(data) {
        return (
          data && {
            ...data,
            flip_balance: TokenAmount.fromAsset(data.flip_balance, 'Flip'),
          }
        );
      },
    });

    const queryClient = useQueryClient();

    useEffect(() => {
      // TODO: check localstorage before init
      init();
    }, []);

    const connect = useCallback(() => {
      if (!apiPromise) {
        init();
      }
    }, [apiPromise]);

    const sendExtrinsic = useCallback(
      async (method, ...rest) => {
        if (isInitializingExtensions)
          throw new Error(
            "We couldn't reach your wallet. Please check there's no pending requests from other wallet extensions.",
          );
        if (!apiPromise)
          throw new Error(
            'Unable to initialize a Websocket connection to the Chainflip RPC endpoint. Please make sure that you are connected to the internet.',
          );
        if (!polkadotAccount) throw new Error('wallet account not initialized');
        if (!polkadotSigner) throw new Error('no signer for connected account');

        let args: SendExtrinsicMap[typeof method];
        let opts: SendExtrinsicOptions | undefined;

        if (isArgsWithOptions(rest)) {
          [{ args, ...opts }] = rest;
        } else {
          args = rest;
        }

        switch (method) {
          case 'set-lp-role': {
            const { resolve, reject, promise } = deferredPromise({ onSettled: refetch });

            const unsub = await apiPromise.tx.liquidityProvider
              .registerLpAccount()
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) {
                    resolve({
                      hash: status.asInBlock.toString(),
                    });
                  }
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'set-refund-address': {
            const { resolve, reject, promise } = deferredPromise({
              onSettled: refetch,
            });

            // eslint-disable-next-line prefer-const
            let [chain, address] = args as SendExtrinsicMap['set-refund-address'];
            const { addressType } = chainConstants[chain];

            if (addressType === 'Dot') {
              address = bytesToHex(ss58.decode(address).data);
            } else if (addressType === 'Sol') {
              address = bytesToHex(base58.decode(address));
            }
            const unsub = await apiPromise.tx.liquidityProvider
              .registerLiquidityRefundAddress({
                [addressType]: address,
              })
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) {
                    resolve({
                      hash: status.asInBlock.toString(),
                    });
                  }
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'open-channel': {
            const { resolve, reject, promise } = deferredPromise<
              ExtrinsicResultMap['open-channel']
            >({ onSettled: refetch });
            const asset = (args as SendExtrinsicMap['open-channel'])[0];
            const argCount =
              apiPromise.tx.liquidityProvider.requestLiquidityDepositAddress.meta.args.length;
            const requestLiquidityDepositAddressArgs = [
              asset,
              (args as SendExtrinsicMap['open-channel'])[1] || 0,
            ].slice(0, argCount);

            const unsub = await apiPromise.tx.liquidityProvider
              .requestLiquidityDepositAddress(...requestLiquidityDepositAddressArgs)
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                async ({ status, events, dispatchError }) => {
                  if (status.isReady) {
                    opts?.onSubmitted();
                  }

                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (!status.isInBlock) return;

                  const eventData = getEventArgs(
                    'liquidityProvider.LiquidityDepositAddressReady',
                    events,
                  );

                  if (eventData) {
                    try {
                      const block = await apiPromise.derive.chain.getHeader(status.asInBlock);

                      resolve({
                        blockNumber: block.number.toNumber(),
                        ...eventData,
                      });
                    } catch (e) {
                      reject(
                        Error(
                          `Failed to fetch block number for block hash: ${status.asInBlock.toString()}`,
                        ),
                      );
                    }
                  } else {
                    reject(Error('failed to parse information from event'));
                  }
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'withdraw-liquidity': {
            const { resolve, reject, promise } = deferredPromise<
              ExtrinsicResultMap['withdraw-liquidity']
            >({ onSettled: refetch });

            if (account?.role !== 'liquidity_provider') {
              reject(new Error('account is not a liquidity provider'));
              return promise;
            }

            const chain = args[0] as SendExtrinsicMap['withdraw-liquidity'][0];
            const { addressType } = chainConstants[chain];
            const asset = args[1] as SendExtrinsicMap['withdraw-liquidity'][1];
            const amount = (args[2] as SendExtrinsicMap['withdraw-liquidity'][2]).toString();
            let refundAddress = account.refund_addresses[chain];

            assert(refundAddress, 'refund address must be present to have liquidity');

            if (addressType === 'Dot') {
              refundAddress = bytesToHex(ss58.decode(refundAddress).data);
            } else if (addressType === 'Sol') {
              refundAddress = bytesToHex(base58.decode(refundAddress));
            }

            const unsub = await apiPromise.tx.liquidityProvider
              .withdrawAsset(amount, asset, {
                [addressType]: refundAddress,
              })
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                async ({ status, dispatchError, txIndex }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) {
                    const block = await apiPromise.derive.chain.getHeader(status.asInBlock);
                    resolve({
                      hash: status.asInBlock.toString(),
                      blockNumber: block.number.toNumber(),
                      extrinsicIndexInBlock: txIndex,
                    });
                  }
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'redeem-flip': {
            const { resolve, reject, promise } = deferredPromise<ExtrinsicResultMap['redeem-flip']>(
              { onSettled: refetch },
            );
            const [amount, ethAddress] = args as SendExtrinsicMap['redeem-flip'];

            const unsub = await apiPromise.tx.funding
              .redeem(amount, ethAddress, null)
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                async ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) resolve({ hash: status.asInBlock.toString() });
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'set-limit-order': {
            const [params] = args as SendExtrinsicMap['set-limit-order'];

            const { resolve, reject, promise } = deferredPromise<
              ExtrinsicResultMap['set-limit-order']
            >({
              onSettled: () => {
                refetch();
                queryClient.invalidateQueries({
                  queryKey: getAccountPoolOrderQueryKey(
                    params.baseAsset,
                    params.quoteAsset,
                    polkadotAccount.idSs58,
                  ),
                });
              },
            });

            const unsub = await apiPromise.tx.liquidityPools
              .setLimitOrder(
                params.baseAsset,
                params.quoteAsset,
                params.side,
                params.orderId.toString(),
                params.tick.toString(),
                params.sellAmount.toString(),
              )
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                async ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) resolve({ hash: status.asInBlock.toString() });
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'set-range-order': {
            const [params] = args as SendExtrinsicMap['set-range-order'];
            const { resolve, reject, promise } = deferredPromise<
              ExtrinsicResultMap['set-range-order']
            >({
              onSettled: () => {
                refetch();
                queryClient.invalidateQueries({
                  queryKey: getAccountPoolOrderQueryKey(
                    params.baseAsset,
                    params.quoteAsset,
                    polkadotAccount.idSs58,
                  ),
                });
              },
            });

            const unsub = await apiPromise.tx.liquidityPools
              .setRangeOrder(
                params.baseAsset,
                params.quoteAsset,
                params.orderId.toString(),
                [params.lowerTick.toString(), params.upperTick.toString()],
                {
                  AssetAmounts: {
                    maximum: {
                      base: params.maxBaseAmount.toString(),
                      quote: params.maxQuoteAmount.toString(),
                    },
                  },
                },
              )
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                async ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) resolve({ hash: status.asInBlock.toString() });
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'set-broker-role': {
            const { resolve, reject, promise } = deferredPromise({ onSettled: refetch });

            const unsub = await apiPromise.tx.swapping
              .registerAsBroker()
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) {
                    resolve({
                      hash: status.asInBlock.toString(),
                    });
                  }
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'add-boost-funds': {
            const [asset, amount, tier] = args as SendExtrinsicMap['add-boost-funds'];

            const { resolve, reject, promise } = deferredPromise({
              onSettled: () => {
                refetch();

                // wait for data in cache to be updated
                setTimeout(() => {
                  queryClient.invalidateQueries({
                    queryKey: [getAllBoostPoolBalancesForAccount],
                  });
                  queryClient.invalidateQueries({
                    queryKey: [getBoostPoolCacheQuery],
                  });
                }, 2000);
              },
            });

            const chainAndAsset = getChainAndAsset(asset);

            const pallet = uncapitalize(`${chainAndAsset.chain}IngressEgress` as const);

            const unsub = await apiPromise.tx[pallet]
              .addBoostFunds(asset, amount.toString(), tier)
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) {
                    resolve({
                      hash: status.asInBlock.toString(),
                    });
                  }
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'stop-boosting': {
            const [asset, tier] = args as SendExtrinsicMap['stop-boosting'];

            const { resolve, reject, promise } = deferredPromise({
              onSettled: () => {
                refetch();

                // wait for data in cache to be updated
                setTimeout(() => {
                  queryClient.invalidateQueries({
                    queryKey: [getAllBoostPoolBalancesForAccount],
                  });
                  queryClient.invalidateQueries({
                    queryKey: [getBoostPoolCacheQuery],
                  });
                }, 2000);
              },
            });

            const chainAndAsset = getChainAndAsset(asset);

            const pallet = uncapitalize(`${chainAndAsset.chain}IngressEgress` as const);

            const unsub = await apiPromise.tx[pallet]
              .stopBoosting(asset, tier)
              .signAndSend(
                polkadotAccount.idHex,
                { signer: polkadotSigner },
                ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) resolve({ hash: status.asInBlock.toString() });
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          default:
            return unreachable(method, 'unknown extrinsic method');
        }
      },
      [polkadotAccount, apiPromise, account],
    ) as SendExtrinsic;

    const estimateExtrinsicFee = useCallback(
      async (method) => {
        if (isInitializingExtensions)
          throw new Error(
            "We couldn't reach your wallet. Please check there's no pending requests from other wallet extensions.",
          );
        if (!apiPromise)
          throw new Error(
            'Unable to initialize a Websocket connection to the Chainflip RPC endpoint. Please make sure that you are connected to the internet.',
          );
        if (!polkadotAccount) throw new Error('wallet account not initialized');

        let paymentInfo;
        switch (method) {
          case 'set-limit-order': {
            paymentInfo = await apiPromise.tx.liquidityPools
              .setLimitOrder('Flip', 'Usdc', 'Buy', BigInt(Date.now()), 0, String(1e18))
              .paymentInfo(polkadotAccount.idHex);
            break;
          }
          case 'set-range-order': {
            paymentInfo = await apiPromise.tx.liquidityPools
              .setRangeOrder(
                'Flip',
                'Usdc',
                BigInt(Date.now()),
                [MIN_TICK.toString(), MAX_TICK.toString()],
                {
                  AssetAmounts: {
                    maximum: {
                      base: String(1e18),
                      quote: String(1e6),
                    },
                  },
                },
              )
              .paymentInfo(polkadotAccount.idHex);
            break;
          }
          default:
            throw new Error(`fee estimation not implemented for extrinsic method "${method}"`);
        }

        return TokenAmount.fromAsset(paymentInfo.partialFee.toString(), 'Flip');
      },
      [polkadotAccount, apiPromise, account],
    ) as EstimateExtrinsicFee;

    // flatten all chain balances for easy retrieval
    const accountInfo = useMemo(
      () => parseAccountInfo(account, assetPrices),
      [account, assetPrices],
    );

    const memoizedContext = useMemo(
      () => ({
        account: account?.role === onboardedRole ? (account as Role[R]) : null,
        accountInfo: account,
        sendExtrinsic,
        estimateExtrinsicFee,
        connect,
        isLoading,
        accountId: polkadotAccount?.idSs58 ?? null,
        flipBalance: account?.flip_balance ?? null,
        ...accountInfo,
        AccountSuspense: null as unknown as Context<T, R>['AccountSuspense'],
        runtimeVersion,
      }),
      [account, connect, isLoading, accountInfo, polkadotAccount?.idSs58],
    );

    return (
      <StateChainAccountContext.Provider value={memoizedContext}>
        {children}
      </StateChainAccountContext.Provider>
    );
  }

  function useStateChainAccount(): Context<T, R> | OnboardedContext<T, R> {
    const ctx = useContext(StateChainAccountContext);
    if (ctx === null) {
      throw new Error('useStateChainAccount must be used within a StateChainAccountContext');
    }

    const AccountSuspense = (({ fallback, children }) => {
      if (fallback && ctx!.account?.role !== onboardedRole) return fallback;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return children({ ...ctx, fallback } as any);
    }) as Context<T, R>['AccountSuspense'];

    return { ...ctx, AccountSuspense };
  }

  return { StateChainAccountProvider, useStateChainAccount };
}

export interface UseStateChainAccount<T, R extends AccountRole> {
  (): Context<T, R> | OnboardedContext<T, R>;
}
